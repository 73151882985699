import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import './EventsWebinarLandingHeader.scss';
import LoadMoreButton from '../../LoadMoreButton';
import EventsWebinarTile from '../../EventsWebinarTile';

export default function EventsWebinarLandingHeader({
  header,
  buttonLabel,
  contentData,
  basePath,
  location,
}) {
  const urlParams = new URLSearchParams(location?.search);
  const numberOfTilePerPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_PER_PAGE);
  const eventsWebinarList = contentData?.map((eventsWebinar) => {
    const dateinTimestamp = new Date(eventsWebinar?.dateOfPublishing).getTime();
    return {
      ...eventsWebinar,
      dateinTimestamp: dateinTimestamp,
    };
  });
  const comparePublishingDate = (a, b) => {
    return b.dateinTimestamp - a.dateinTimestamp;
  };
  const nowTime = new Date(Date.now()).getTime()
  const pastEventsWebinarList = eventsWebinarList.filter((eventsWebinar)=>eventsWebinar.dateinTimestamp<=nowTime && eventsWebinar?.pageVisible)
  const eventsWebinars = pastEventsWebinarList.sort(comparePublishingDate);
  const [colsCount, setColsCount] = useState(numberOfTilePerPage);
  const tilesAddMore = () => {
    let nextColsCount =
      contentData.length > colsCount + numberOfTilePerPage
        ? colsCount + numberOfTilePerPage
        : contentData.length;
    setColsCount(nextColsCount);
  };
  useEffect(() => {
    setColsCount(numberOfTilePerPage);
  }, [location.href]);
  return (
    <>
      <Grid container className="event-webinar-cls">
        {/* Title Starts */}
        {eventsWebinars?.length ? (
          <>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
            <h1 locId="header" className="event-title">
              {header}
            </h1>
          </Grid>
          <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
        </Grid>
        {/* Title Ends */}
        <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
        <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className="research-container">
          <Grid container className="events-card-container">
            {/* <EventsWebinarTile data={eventsWebinars.slice(0, colsCount)} basePath={basePath} /> */}
            {eventsWebinars.slice(0,colsCount).map((eventWeb) => <EventsWebinarTile data={eventWeb} basePath={basePath} key={eventWeb.slug}/>)}
          </Grid>
        </Grid>
        </>
        ) : (
          <> </>
        )}

        <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
        {colsCount < eventsWebinars?.length ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <LoadMoreButton handleLoadMore={tilesAddMore} loadMoreButton={buttonLabel} />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
