import React from 'react';
import { myStyles } from './style';
import clsx from 'clsx';
import './EventsWebinarLanding.scss';
import EventsWebinarLandingHeader from './EventsWebinarLandingHeader';
import Container from '@material-ui/core/Container';

export default function EventsWebinarLanding({ landingData, contentData, basePath, location }) {
  const classes1 = myStyles(landingData?.capsulesBackgroundImage);
  const classes2 = myStyles(landingData?.leftDonutBackgroundImage);
  const classes3 = myStyles(landingData?.dotMatrixBackgroundImage);
  return (
    <>
      <div className="eventwebinar-landing-main">
        <div className={clsx('bg-capsules-eventwebinar-landing', classes1['bg-capsules'])}>
          <div
            className={clsx(
              'bg-donuts-eventwebinar-landing',
              classes2['bg-donut'],
              classes3['bg-dot-matrix']
            )}
          >
            <Container maxWidth="xl" className="event-container-div">
              <EventsWebinarLandingHeader
                header={landingData?.header}
                buttonLabel={landingData?.loadMoreButton}
                contentData={contentData}
                basePath={basePath}
                location={location}
              />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
