import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-capsules': (capsulesImg) => ({
    background: `url(${capsulesImg?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-donut': (donutImg) => ({
    background: `url(${donutImg?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-dot-matrix': (dotMatrixBackgroundImage) => ({
    '&:after': {
      backgroundImage: `url(${dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100)`,
    },
  })
}));
