import React,{useEffect,useState} from 'react';
import './EventsWebinarTile.scss';
import EventsWebinarWithoutVideoTile from './EventsWebinarWithoutVideoTile';
import EventsWebinarWithVideoTile from './EventsWebinarWithVideoTile';
export default function EventsWebinarTile({ data, basePath }) {
  const [contentData,setContentData]=useState('')
  useEffect(()=>{
    setContentData({...data})
  },[])
  return (
    <>
      {data?.imageOrVideo ? (
          <EventsWebinarWithoutVideoTile eventsWebinar={contentData} basePath={basePath} />
        ) : (
          <EventsWebinarWithVideoTile eventsWebinar={contentData} basePath={basePath} />
        )
      }
    </>
  );
}
