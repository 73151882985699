import React from 'react';
import { CardMedia, Card, CardContent } from '@material-ui/core';
import { DateFormatUtils } from '../../../utils/date-formatter';
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core';
export default function EventsWebinarWithoutVideoTile({ eventsWebinar, basePath }) {
  const { date, month, year } = DateFormatUtils.DateMonthYearFormatter(eventsWebinar?.dateOfEvent);
  return (
    <>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="event-card-item-col">
        <Link to={`/${basePath}/${eventsWebinar?.slug}`} tabIndex="0" className='events-webinar-card-link'>
          <Card className="events-webinar-card" tabIndex="-1">
            <CardMedia
              component="img"
              alt={eventsWebinar?.eventThumbnailImage?.description}
              height="240"
              image={eventsWebinar?.eventThumbnailImage?.file?.url+'?fm=webp&q=100'}/>
            <CardContent className="card-content">
              <Grid item container spacing={2}>
                <Grid item direction="column">
                  <div locId="eventsOrWebinarTitle" className="events-web-title">
                    {eventsWebinar?.eventsOrWebinarTitle}
                  </div>
                  <div locId="dateOfEvent" className="events-web-date">
                    {date} {month} {year}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </>
  );
}
